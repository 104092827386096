.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


html {
  height:100%;
}

body {
  margin:0;
}

.bg {
  animation:slide 30000s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg, #fff 50%, #09f 50%);
  bottom:0;
  left:-50%;
  opacity:.5;
  position:fixed;
  right:-50%;
  top:0;
  z-index:-1;
}

.bg2 {
  animation-direction:alternate-reverse;
  animation-duration:4s;
}

.bg3 {
  animation-duration:5s;
}

.content {
  background-color:rgba(255,255,255,.8);
  border-radius:.25em;
  box-shadow:0 0 .25em rgba(0,0,0,.25);
  box-sizing:border-box;
  left:50%;
  padding:10vmin;
  position:fixed;
  text-align:center;
  top:50%;
  transform:translate(-50%, -50%);
}

@keyframes slide {
  0% {
    transform:translateX(-25%);
  }
  100% {
    transform:translateX(25%);
  }
}


@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-card {
  animation: fadeIn 2s ease-in-out;
}


@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px); 
  }
  100% {
    opacity: 1;
    transform: translateX(0); 
  }
}

.fade-in-left {
  animation: fadeInLeft 2s ease-in-out;
}


@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px); 
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-in-right {
  animation: fadeInRight 2s ease-in-out;
}

@keyframes slide-zoom-fade {
  0% {
    transform: translateY(50px) scale(0.8);
    opacity: 0;
  }
  50% {
    transform: translateY(0) scale(1.05);
    opacity: 0.7;
  }
  100% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}

.slide-zoom-fade {
  animation: slide-zoom-fade 1.3s ease-out both;
}
